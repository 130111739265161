import { landingPageMode } from '@/services/states/states.service'
import { debounce } from '@/services/utils/utils.service'

// ckeditor@dep
import ButtonView from '@ckeditor/ckeditor5-ui/src/button/buttonview'
import Plugin from '@ckeditor/ckeditor5-core/src/plugin'
import LinkUI from '@ckeditor/ckeditor5-link/src/linkui'
import i18n from '@/plugins/i18n'
// ckeditor@dep

// Default style conf
// import textDefaultStyle from '@/assets/config/default-style/text.conf'

// Conf@buttonsList
/**
 * List of buttons requested for unsubscribe feature
 * @type {Array}
 */
const startingId = 'std'
const buttonsList = landingPageMode().get()
  ? [
      { id: 'std', href: true }
    ]
  : [
      { id: 'std', href: true },
      { id: 'miroir', href: false },
      { id: 'desabo', href: false },
      { id: 'desabo-ext', href: true }
    ]
// Conf@buttonsList

// Conf@translation
/**
 * Translation key based on vue-translate
 * @type {Object}
 */
const translation = {
  std: i18n.t('t_std-link_'),
  miroir: i18n.t('t_mirror-link_'),
  desabo: i18n.t('t_desabo-link_'),
  'desabo-ext': i18n.t('t_desabo-ext-link_')
}
// Conf@translation

/**
 * Numberly Unsubscribe Links plugin
 * for emailing
 */
export default class UnsubscribeLinks extends Plugin {
  // Class@init
  init () {
    this._id = startingId
    this._linkUI = this.editor.plugins.get(LinkUI)
    this._linkCommand = this.editor.commands.get('link')
    this._linkFormView = this._linkUI.formView

    this._setFormValidation()
    this._setMenuStateOnEdit()
    this._generateUnsubscribeButtons()
  }
  // Class@init

  // Class@setMenuStateOnEdit
  /**
   * On link edition update menu state
   */
  _setMenuStateOnEdit () {
    this._linkUI.actionsView.editButtonView
      .on('execute', () => {
        setTimeout(() => {
          this._id = this._linkFormView.idInputView.element.querySelector('input').value
          const button = buttonsList.find(button => button.id === this._id)
          this._linkFormView.element.setAttribute('class', `ck ck-link-form ${this._id}`)
          this._linkFormView.urlInputView.isReadOnly = !button.href
        })
      })
  }
  // Class@setMenuStateOnEdit

  // Class@generateUnsubscribeButtons
  /**
   * Generate buttons and add them to the existing link UI
   */
  _generateUnsubscribeButtons () {
    buttonsList.forEach(button => {
      const { id, href } = button
      this[`but-${id}`] = this._createButton({ id, hasHref: href })
    })

    this._linkFormView.urlInputView.fieldView.placeholder = i18n.t('t_enter-url_')
    this._linkFormView.once('render', () => {
      const buttonsTypeDom = document.createElement('div')
      buttonsTypeDom.setAttribute('class', 'link-type')
      buttonsList.forEach(button => {
        const { id } = button
        this[`but-${id}`].render()
        this[`but-${id}`].element.className += ` ${id}`
        this._linkFormView.registerChild(this[`but-${id}`])
        buttonsTypeDom.appendChild(this[`but-${id}`].element)
      })

      const butValWrapper = document.createElement('div')
      butValWrapper.setAttribute('class', 'link-validation')
      butValWrapper.appendChild(this._linkFormView.cancelButtonView.element)
      butValWrapper.appendChild(this._linkFormView.saveButtonView.element)

      this._linkFormView.element.appendChild(buttonsTypeDom)
      this._linkFormView.element.appendChild(butValWrapper)
      this._linkFormView.element.setAttribute('class', `ck ck-link-form ${startingId}`)

      this._linkFormView.saveButtonView.isEnabled = false
    })
  }
  // Class@generateUnsubscribeButtons

  // Class@createButton
  /**
   * Create a buttonh based on buttonsList configuration
   * @param  {String} options.id   (std, miror, desabo, etc...)
   * @param  {String} options.href (URL)
   *
   * @return {Object}              (button for ckEditor)
   */
  _createButton ({ id, hasHref }) {
    const button = new ButtonView(this.locale)

    button.set({
      label: translation[id],
      withText: true,
      tooltip: id === 'desabo-ext' ? i18n.t('t_enter-url_') : false
    })
    button.bind('isEnabled').to(this._linkCommand)

    // On button click
    button.on('execute', () => {
      this._id = id
      this._linkFormView.idInputView.fieldView.value = this._id
      this._linkFormView.urlInputView.fieldView.element.value = ''

      // If no URL requested
      if (!hasHref) {
        // Clear input value, disable input field and update placeholder message
        this._linkFormView.urlInputView.fieldView.isReadOnly = true
        this._linkFormView.urlInputView.fieldView.value = ''
        this._linkFormView.altInputView.fieldView.value = translation[this._id]
        this._linkFormView.urlInputView.fieldView.placeholder = i18n.t('t_no-url-requested_')
      } else {
        // If URL is requested enable input & update placeholder message
        this._linkFormView.urlInputView.fieldView.value = ''
        this._linkFormView.urlInputView.fieldView.element.value = ''
        this._linkFormView.altInputView.fieldView.value = this._linkFormView.urlInputView.fieldView.value
        this._linkFormView.urlInputView.fieldView.isReadOnly = false
        this._linkFormView.urlInputView.fieldView.placeholder = i18n.t('t_enter-url_')
      }

      const noUserActionRequested = !((this._id === 'desabo-ext' || this._id === 'std') && !this._linkFormView.urlInputView.fieldView.value)
      this._linkFormView.saveButtonView.isEnabled = noUserActionRequested
      if (this._linkFormView.element) this._linkFormView.element.setAttribute('class', `ck ck-link-form ${this._id}`)
    })

    return button
  }
  // Class@createButton

  // Class@setFormValidation
  /**
   * Set form validation to disable validation
   * when the input field is required
   */
  _setFormValidation () {
    const that = this

    this._linkFormView.once('render', () => {
      this._linkFormView.urlInputView.fieldView.element
        .addEventListener('input', function () {
          const input = this
          debounce({
            type: 'checkUrl',
            service: UnsubscribeLinks,
            func: '_validateUrl',
            params: [that, input],
            delay: 300
          })
        })
    })
  }
  // Class@setFormValidation

  // TODO (anga):  Quickly disable regexp validation for now, maybe
  // URL validation will come back in the future
  static _validateUrl (that, input) {
    // const value = input.value.trim()
    // const linkCheck = textDefaultStyle.links.check
    const isValidForm = true
    // const regExpPurePerso = new RegExp(`^${linkCheck.perso}`, 'i')
    // const regExpMailto = new RegExp(linkCheck.mailto, 'i')
    // const regExpURL = new RegExp(
    //   linkCheck.protocol +
    //   linkCheck.domain +
    //   linkCheck.path +
    //   linkCheck.queryString +
    //   linkCheck.fragmentLocator, 'i'
    // )

    // const isValidForm = !(
    //   (that._id === 'desabo-ext' || that._id === 'std') &&
    //   (!value || !regExpURL.test(value)) &&
    //   (!value || !regExpMailto.test(value)) &&
    //   (!value || !regExpPurePerso.test(value))
    // )

    // Enable or disable save button depending form validation
    if (isValidForm) {
      that._linkFormView.altInputView.fieldView.value = input.value
    }
    that._linkFormView.saveButtonView.isEnabled = isValidForm
  }
}
